<template>
    <el-dialog :close-on-click-modal="false" title="新增配置项" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">



            <el-form-item label="类型名称" prop="title">
                <el-input v-model="value.title" placeholder="请输入类型名称" />
            </el-form-item>


            <el-form-item label="排序" prop="title">
                <el-input v-model="value.title" placeholder="请输入排序" />
            </el-form-item>




        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,


                // state: true,

                // radio: '1',


                // options: [{
                //   valueKEY: '选项1',
                //   label: '天津南开卫健委'
                // }, {
                //   valueKEY: '选项2',
                //   label: '天津西青卫健委'
                // }, ],
                // valueSEL: '',

                // options1: [{
                //   valueKEY: '选项1',
                //   label: '天津南开卫健委'
                // }, {
                //   valueKEY: '选项2',
                //   label: '天津西青卫健委'
                // }, ],
                // valueSEL1: '',

            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择配置分组"],
  .selfInputBoxIV .el-input__inner[placeholder="请选择配置类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
</style>